import { useEffect, useState } from 'react';
import LoginComponent from '@/components/LoginAndDropdownComponent';
import store from 'src/redux/store';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { btnActionHandler } from '../../ModernTheme/utils/btnUtils';
import { cloneDeep } from 'lodash';

export const logoSectionConfigDetails = (activeHeaderData, isScroll) => {
  const { headerPropsConfig } = activeHeaderData || {};
  const { logo_section, globalClass } = headerPropsConfig || {};
  const { staticLogo, scrollLogo } = logo_section || {};
  const currentType = isScroll ? scrollLogo : staticLogo;
  const isTransparent =
    globalClass?.staticClass?.base_config?.defaultFillColorType === 'transparent';
  const {
    base_config,
    componentData: { url },
  } =
    scrollLogo?.componentData?.url?.length > 0 && isTransparent
      ? currentType
      : staticLogo;
  const storeName = store.getState().storeReducer.store?.store_info?.name?.trim();
  return {
    hidden: !(base_config.showLogo || base_config.showStoreName),
    onlyLogo: base_config.showLogo && url && !(base_config.showStoreName && storeName),
    onlyStoreName:
      base_config.showStoreName && storeName && !(base_config.showLogo && url),
    logoAndName: base_config.showStoreName && storeName && base_config.showLogo && url,
  };
};

export function getMaxCharactersAllowedInNavLinks({
  containerRef,
  headerName,
  isBuilder,
  headerWidth,
  navlinksPosition,
  searchBarType,
}) {
  const sectionWidth = containerRef.current?.clientWidth;
  const preferredWidth = (isBuilder ? sectionWidth : headerWidth) - 18;
  let charLengthAllowed: 'unset' | number = 'unset';
  if (headerName === 'header1') {
    if (preferredWidth <= 720) {
      if (searchBarType === 'field')
        charLengthAllowed = navlinksPosition === 'left' ? 17 : 7;
      if (searchBarType === 'icon') charLengthAllowed = 21;
      if (searchBarType === 'labelIcon') charLengthAllowed = 21;
    }
    if (preferredWidth > 720 && preferredWidth < 1280) {
      if (searchBarType === 'field')
        charLengthAllowed = navlinksPosition === 'left' ? 17 : 7;
      if (searchBarType === 'icon') charLengthAllowed = 21;
      if (searchBarType === 'labelIcon') charLengthAllowed = 21;
    }
    if (preferredWidth >= 1280 && preferredWidth <= 1500) {
      if (searchBarType === 'field') charLengthAllowed = 20;
      if (searchBarType === 'icon') charLengthAllowed = 58;
      if (searchBarType === 'labelIcon') charLengthAllowed = 53;
    }

    if (preferredWidth > 1500 && preferredWidth <= 1800) {
      if (searchBarType === 'field') charLengthAllowed = 34;
      if (searchBarType === 'icon') charLengthAllowed = 77;
      if (searchBarType === 'labelIcon') charLengthAllowed = 64;
    }

    if (preferredWidth > 1800) {
      if (searchBarType === 'field') charLengthAllowed = 70;
      if (searchBarType === 'icon') charLengthAllowed = 92;
      if (searchBarType === 'labelIcon') charLengthAllowed = 81;
    }
  }

  if (headerName === 'header2') {
    if (preferredWidth <= 720) {
      charLengthAllowed = 16;
    }
    if (preferredWidth > 720 && preferredWidth <= 1100) {
      charLengthAllowed = 15;
    }
    if (preferredWidth > 1100 && preferredWidth <= 1500) {
      charLengthAllowed = 31;
    }
    if (preferredWidth > 1500 && preferredWidth <= 1800) {
      charLengthAllowed = 35;
    }
    if (preferredWidth > 1800) {
      charLengthAllowed = 49;
    }
  }

  return charLengthAllowed;
}


function checkForShopComponent(linksData) {
  linksData?.forEach((data) => {
     if(data?.isShopComponent && data?.option?.length && data?.type === 'text')
      {
        data.type = 'dropdown';
        data.redirectionLink = ""
      }
  })

  return cloneDeep(linksData);
}

export const useGetNavlinkList = ({
  activeHeaderData,
  headerData,
  dynamicSectionData,
  headerNodeRef,
  isBuilder,
  componentData,
  headerName,
  containerRef,
}) => {
  const headerWidth = headerNodeRef?.clientWidth || (!IS_SERVER ? window.innerWidth : 0);
  const navlinksPosition =
    activeHeaderData?.headerPropsConfig?.navLinks_section?.base_config?.defaultPosition;
  const visibleLinks = componentData?.filter((data) => data?.showOnHeader);
  const { base_config: dynamicSectionBaseConfig } = dynamicSectionData;
  const { defaultType: searchBarType } = dynamicSectionBaseConfig;
  const [navlinkList, setNewList] = useState([]);

  function checkIfMoreAlreadyThere(visibleLinks) {
    let moreIndex = -1;
    for (let i = 0; i < visibleLinks?.length; i++) {
      if (visibleLinks[i]?.name?.toLowerCase() === 'more') {
        moreIndex = i;
        break;
      }
    }
    return moreIndex;
  }

  function removeMoreOption(restElements) {
    return restElements?.filter((data) => data?.name?.toLowerCase() !== 'more');
  }

  function getNavLinkSliceCount() {
    const charLengthAllowed = getMaxCharactersAllowedInNavLinks({
      containerRef,
      headerName,
      isBuilder,
      headerWidth,
      navlinksPosition,
      searchBarType,
    });

    let sliceCount = visibleLinks.length;
    if (charLengthAllowed === 'unset') {
      return sliceCount;
    }
    let currentCharacterCount = 0;
    for (let currentIndex = 0; currentIndex <= visibleLinks.length - 1; currentIndex++) {
      currentCharacterCount =
        currentCharacterCount + visibleLinks[currentIndex].name.length;
      if (currentCharacterCount > charLengthAllowed) {
        break;
      } else {
        sliceCount = currentIndex + 1;
      }
    }
    return sliceCount;
  }

  useEffect(() => {
    if (
      headerData?.row2?.length > 0 ||
      headerName === 'header3' ||
      headerName === 'header4'
    ) {
      if (visibleLinks?.length > 9) {
        setNewList(visibleLinks?.slice(0, 9));
      } else {
        setNewList(visibleLinks);
      }
      return;
    }

    // let sliceCount = componentData?.length;
    let sliceCount = 1;

    sliceCount = getNavLinkSliceCount();

    let newComponentData = [];
    if (sliceCount > visibleLinks?.length) {
      newComponentData = visibleLinks;
    } else {
      const currentData = visibleLinks?.slice(0, sliceCount);
      const restElements = visibleLinks?.slice(sliceCount, visibleLinks?.length);
      let newData = [];
      const moreIndex = checkIfMoreAlreadyThere(visibleLinks);
      if (restElements?.length) {
        newData = {
          name: 'More',
          type: 'dropdown',
          option:
            moreIndex >= 0
              ? [...visibleLinks?.[moreIndex]?.option, ...removeMoreOption(restElements)]
              : [...restElements],
          showOnHeader: true,
          elementLevelClass: '',
        } as any;
      }
      newComponentData = [
        ...(restElements?.length > 0 ? removeMoreOption(currentData) : currentData),
        newData,
      ];
    }

    setNewList(newComponentData);
  }, [componentData, headerName, componentData?.length, visibleLinks?.length]);
   
  const updatedNavLinks = checkForShopComponent(navlinkList)
  return { navlinkList: updatedNavLinks };
};

export const getHamburgerCustomList = (
  activeHeaderData,
  onMenuDrawerItemClick,
  toggleMenuDrawerOnMobile
) => {
  const drawerCustomList =
    activeHeaderData.headerPropsConfig.navIcons_section?.componentData?.iconsList
      ?.filter((item) => item.visibility?.hamburger === true)
      .reverse()
      .map((item) => {
        return item.name === 'loginLogout' ? (
          <LoginComponent disableDropdown>
            <div>{item.label}</div>
          </LoginComponent>
        ) : (
          <p
            key={item.label}
            className="tw-m-0 "
            onClick={() =>
              onMenuDrawerItemClick(item.action.actionType, item.action.actionData)
            }
          >
            {item.label}
          </p>
        );
      }) || [];
  const buttonList =
    activeHeaderData?.headerPropsConfig?.buttons?.map((item) => {
      return (
        <p
          key={item.text}
          className="tw-m-0 "
          onClick={() => {
            toggleMenuDrawerOnMobile(false);
            btnActionHandler(item?.actions, () => {});
          }}
        >
          {item.text}
        </p>
      );
    }) || [];

  return drawerCustomList?.concat(buttonList) || [];
};
