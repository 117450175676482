import classNames from 'classnames';
import styles from './shopComponent.module.scss';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import router from 'next/router';
import { PATH, getRoute } from 'src/utils/routes';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { useSSRSelector } from '@/redux/ssrStore';

export const NavigationDropdown = ({ top, optionsList, ...props }) => {
  const { isShopComponent = false, categories = {} } = props;
  const store = useSSRSelector((state) => state.storeReducer.store);
  if (!optionsList?.length) {
    return null;
  }
  const { onLinkHoverEnter, onLinkHoverLeave, getNavElementStyle } =
    props.customItemConfigs || {};

  function handleDropdownNavClick(link, newpage, name, data) {
    if (isShopComponent) {
      const currentCategory = categories?.find((val) => val?.name === name);
      if(currentCategory?.id) {
        router.push(
          getRoute(
            `${
              isPLPRequiredSystem(store?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
            }?cid=${currentCategory?.id}`,
            store?.store_info?.domain
          ),
          undefined,
          { shallow: true }
        );
      }
      else {
        data?.onClick();
      }
    } else {
      props.onNavItemClick(link, newpage);
    }
  }

  return (
    <div
      style={{ top: `${top}px`, color: '#000', ...(props.dropDownStyle || {}) }}
      className={classNames(
        props.wrapperClass,
        'tw-z-10 ',
        !props.fromMobileMenuDrawer
          ? classNames('tw-relative  ', styles['cfe-dropdown-nav'])
          : ' tw-overflow-y-auto tw-p-0 [&>div>p]:tw-text-[13px] [&>div>p]:tw-font-semibold [&>div>p]:tw-text-newBlack [&>div]:!tw-border-l-0 [&>div]:!tw-pl-0'
      )}
    >
      <div
        style={{ maxHeight: '360px' }}
        className={classNames(
          !props.fromMobileMenuDrawer
            ? classNames(styles['cfe-dropdown-nav__items'], 'tw-p-[20px]')
            : 'tw-mt-[22.5px]'
        )}
      >
        {optionsList
          .filter((item) => item.showOnHeader)
          ?.map((option, idx, renderArray) => {
            const isNavLinkActive = !IS_SERVER
              ? window.location.pathname.includes(
                  option.redirectionLink?.startsWith('/')
                    ? option.redirectionLink
                    : `/${option.redirectionLink}`
                )
              : false;

            return (
              <div
                key={option.name}
                className={classNames('tw-relative [&>.underline-comp]:hover:tw-block')}
              >
                <div
                  onClick={() =>
                    handleDropdownNavClick(
                      option.redirectionLink,
                      option.redirectNewPage,
                      option.name,
                      option
                    )
                  }
                  className={
                    (!option?.redirectionLink || isNavLinkActive) &&
                    !isShopComponent &&
                    'tw-pointer-events-none'
                  }
                >
                  <p
                    onMouseEnter={() => onLinkHoverEnter(idx)}
                    onMouseLeave={onLinkHoverLeave}
                    onClick={option.onClick}
                    style={getNavElementStyle ? getNavElementStyle(idx) : {}}
                    className={
                      'tw-relative tw-w-fit tw-max-w-[150px] tw-select-none tw-whitespace-normal tw-break-words [&_.underline-comp]:hover:tw-block ' +
                      (!props.fromMobileMenuDrawer
                        ? 'tw-m-0 tw-text-[12px] tw-tracking-[0.95px] ' +
                          (idx !== renderArray.length - 1 ? 'tw-pb-[16px]' : '')
                        : 'tw-m-0  ' +
                          (idx !== renderArray.length - 1 ? 'tw-pb-[17.5px]' : ''))
                    }
                    key={idx.id}
                  >
                    {option.name}
                    {props._RenderUnderlineComponent?.(idx, {
                      top: undefined,
                      bottom: !props.fromMobileMenuDrawer
                        ? idx !== renderArray.length - 1
                          ? 12
                          : ''
                        : idx !== renderArray.length - 1
                          ? -3.5
                          : '',
                      width: '100%',
                      height: '1px',
                    })}
                  </p>
                </div>
              </div>
            );
          })}
        {/* {[...productTags, ...categories]?.map((category, idx, renderArray) => (
          <p
            className={
              'tw-select-none tw-uppercase ' +
              (!props.fromMobileMenuDrawer
                ? 'tw-m-0 tw-text-[12px] tw-font-semibold tw-tracking-[0.95px] ' +
                  (idx !== renderArray.length - 1 ? 'tw-pb-[16px]' : '')
                : 'tw-m-0  ' + (idx !== renderArray.length - 1 ? 'tw-pb-[17.5px]' : ''))
            }
            key={category.id}
            onClick={() => onCategoriesLinkHandle(category)}
          >
            {removeEmoticons(category.name) || 'Available Items'}
          </p>
        ))} */}
      </div>
    </div>
  );
};
